<template>
    <div :class="['legend-panel', withHeader ? 'withHeader' : '']" v-show="!!equipmentTypeList.length">
        <div :class="['ulbox', exClass]">
            <div class="ulbox__device-list">
                <el-checkbox-group v-model="checkList" @change="handleLegendChange">
                    <el-checkbox
                        v-for="(item, index) in equipmentTypeList"
                        :key="index"
                        :label="item.equipmentMainCategory"
                        ><icon-svg :name="item.icon"></icon-svg>{{ item.categoryName }} (
                        {{ item.counts }} )</el-checkbox
                    >
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>

<script>
import {equipmentTypes, equipmentTypeTrans} from './utils';
export default {
    props: {
        allPoints: {
            type: Array,
            default: () => [],
        },
        withHeader: {
            type: Boolean,
            default: false,
        },
        exClass: {
            type: String,
            default: '',
        },
    },
    watch: {
        allPoints(newVal, oldVal) {
            if (!newVal !== oldVal) {
                let dataTypes = new Map();
                this.allPoints.forEach((it) => {
                    let {equipmentMainCategory} = it;
                    if (dataTypes.has(equipmentMainCategory)) {
                        dataTypes.set(equipmentMainCategory, dataTypes.get(equipmentMainCategory) + 1);
                    } else {
                        dataTypes.set(equipmentMainCategory, 1);
                    }
                });
                let types = ['mainSubstation', 'substation', 'ringMainUnit', 'towerPole'].filter((it) => {
                    return dataTypes.has(equipmentTypes[it]);
                });
                let iconTrans = {
                    mainSubstation: 'zhubiandianzhan',
                    substation: 'biandianzhan',
                    ringMainUnit: 'huanwanggui',
                    towerPole: 'ganta',
                };
                let equipmentMainCategoryList = [];
                let equipmentTypeList = types.map((it) => {
                    let equipmentMainCategory = equipmentTypes[it];
                    let categoryName = equipmentTypeTrans[equipmentMainCategory];
                    equipmentMainCategoryList.push(equipmentMainCategory);
                    let icon = iconTrans[it];
                    return {
                        categoryName,
                        equipmentMainCategory,
                        icon,
                        counts: dataTypes.get(equipmentMainCategory),
                    };
                });
                this.equipmentTypeList = equipmentTypeList;
                this.checkList = [...dataTypes.keys()];
            }
        },
    },
    data() {
        return {
            checkList: [],
            equipmentMainCategoryList: [],
            equipmentTypeList: [],
        };
    },
    methods: {
        handleLegendChange(value) {
            let showPoints = this.allPoints.filter((it) => {
                return this.checkList.includes(it.equipmentMainCategory);
            });
            showPoints = this._.cloneDeep(showPoints);
            this.$emit('showPointsChange', showPoints);
        },
    },
    created() {},
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.legend-panel {
    width: 200px;
    background-color: fade(@bg-conent, 90%);
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    &.withHeader {
        top: 60px;
    }
}
.ulbox {
    &__device-list {
        list-style: none;
        font-size: 12px;
        line-height: 0.6;
        color: #3f3f3f;
        margin: 15px 10px;
    }
    &__select-gis {
    }
    &__subtext {
        font-size: 12px;
        line-height: 1.6;
        color: #3f3f3f;
        margin: 0 15px 10px;
        > P {
            margin: 0;
        }
    }
    .icon-margin-right {
        margin-right: 5px;
    }

    /deep/ .el-checkbox__inner{
        width: 12px;
        height: 12px;
    }

    /deep/ .el-checkbox__inner::after{
        height: 6px;
        left: 3px;
        top: 1px;
    }

    /deep/ .el-checkbox {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: @bg-font;
        margin-bottom: 5px;
    }
    /deep/ .el-checkbox__label {
        font-size: 12px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        .icon-svg{
            width: 1.2em;
            margin-right: 5px;
        }
    }

    /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: unset;
    }
    &.bigScreen {
        /deep/ .el-checkbox{
            color: #01FFFB;
        }
        /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #01FFFB;
        }
        /deep/ .el-checkbox__inner::after{
            border-color: #0b1020;
        }
        /deep/ .el-checkbox__inner{
            background-color: #0b1020;
            border-color: #0b1020;
        }
        /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
        /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #01FFFB;
            border-color: #01FFFB;
        }
    }
}
</style>
