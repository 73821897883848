import { render, staticRenderFns } from "./DeviceLegendPanel.vue?vue&type=template&id=451a77f8&scoped=true&"
import script from "./DeviceLegendPanel.vue?vue&type=script&lang=js&"
export * from "./DeviceLegendPanel.vue?vue&type=script&lang=js&"
import style0 from "./DeviceLegendPanel.vue?vue&type=style&index=0&id=451a77f8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451a77f8",
  null
  
)

export default component.exports