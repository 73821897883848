<!--购售电收益-->

<template>
    <div class="box-container">
        <widget-framework
            v-show="dataLoaded"
            title="配网查看"
            :showBorder="false"
            :showTitle="false"
            :showDecoration="false"
        >
             <template #content>
                <device-legend-panel
                    ref="DeviceLegendPanel"
                    :allPoints="allPoints"
                    exClass="bigScreen"
                    @showPointsChange="handleShowPointsChange"
                ></device-legend-panel>
                <map-base
                    @itemClick="handleMapItemClick"
                    @mapLayerClick="handleMapLayerClick"
                    :lines="lines"
                    :gridLines="gridLines"
                    :points="showPoints"
                    ref="mapbase"
                ></map-base>
            </template>
        </widget-framework>
        <dv-loading v-show="!dataLoaded"></dv-loading>
    </div>
</template>

<script>
import MapBase from '@/pages/powerGridMonitor/MapBase.vue';
import mapCommon from '@/pages/powerGridMonitor/mapCommon';
import WidgetFramework from '@/components/widgets/WidgetFramework';
import DeviceLegendPanel from '@/pages/powerGridMonitor/DeviceLegendPanel';
export default {
    mixins: [mapCommon],
    components: {
        MapBase,
        WidgetFramework,
        DeviceLegendPanel
    },
    data() {
        return {
            dataLoaded: false,
            lines: [],
            gridLines: [],
            showPoints: [],
            allPoints: [],
        };
    },
    methods: {
        handleMapItemClick(type, index, data) {
            if (type === 'point') {
                this.showPopper(data);
            }
        },
    },
    created() {
        this.getLayerData(null, () => {
            this.dataLoaded = true;
        });
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.profit {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(~@/assets/images/widget/sum-of-purchase-and-sale-of-electricity.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .profitContainer {
        text-align: center;
        margin-left: -10px;
    }

    .number {
        font-size: 20px;
        font-weight: bold;
        color: @yellow;
    }
    .name {
        font-size: 14px;
    }
}

.sale,
.purchase {
    height: 15%;
    font-size: 12px;
    .label {
        color: @cyan;
        text-align: right;
    }
    .value {
        color: @green;
    }
}
/deep/ .content-inner {
    flex-grow: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.box-container{
    width: 100%;
    height: 100%;
}
</style>
